import http from "../http-common";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import store from "../store";

class RezeptService {
    getAll() {
        let url = "/rezepts"
        if (!AuthService.hasRole(store.state.auth.user, 'eRezeptProcessor')) {
            url += "/mine"
        }
        return http.get(url, { headers: authHeader() });
    }

    getAllUnprocessed() {
        let url = "/rezepts"
        if (!AuthService.hasRole(store.state.auth.user, 'eRezeptProcessor')) {
            url += "/mine"
        }
        return http.get(url, {
            params: {
                unprocessed: 1
            },
            headers: authHeader()
        });
    }

    getAllProcessorView() {
        let url = "/rezepts"
        if (!AuthService.hasRole(store.state.auth.user, 'eRezeptProcessor')) {
            url += "/mine"
        }
        return http.get(url, {
            params: {
                unprocessed: 1
            },
            headers: authHeader()
        });
    }

    printSelected(immunkartesIds) {
        return http.post('/rezepts/print',
            { immunkarteIds: immunkartesIds },
            {
                headers: authHeader(),
                responseType: 'blob',
            }
        )
    }

    markUnprocessed(id) {
        return http.get(`/rezepts/markunprocessed/${id}`, { headers: authHeader() });
    }

    markProcessed(id) {
        return http.get(`/rezepts/markprocessed/${id}`, { headers: authHeader() });
    }

    get(id) {
        return http.get(`/rezepts/${id}`, { headers: authHeader() });
    }

    create(data) {
        return http.post("/rezepts", data, { headers: authHeader() });
    }

    delete(id) {
        return http.delete("/rezepts/" + id, { headers: authHeader() });
    }

    findByPatientData(forename, surname, unprocessed) {
        let url = "/rezepts"
        if (!AuthService.hasRole(store.state.auth.user, 'eRezeptProcessor')) {
            url += "/mine"
        }
        return http.get(url, {
            params: {
                unprocessed: unprocessed,
                forename: forename,
                surname: surname
            },
            headers: authHeader()
        });
    }
}

export default new RezeptService();