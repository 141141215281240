<template>
  <div class="mb-3">
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <h1>eRezept(e) hochladen</h1>

      <div v-if="user" class="mb-3 mt-3">
        <p>Bitte wählen Sie einen Mandanten aus:</p>
        <select v-model="selectedMandant" class="form-select" aria-label="Bitte wählen Sie einen Mandanten aus">
          <option v-for="mandant in user.permissions.filter(p => p.role.name === 'eRezeptCreator').map(p => p.mandant) " v-bind:value="mandant" v-bind:key="mandant.mdts">
            {{ mandant.inhaber.name }} &middot; {{ mandant.firmenbezeichnung.voll }} {{ mandant.adresse.ort }}
          </option>
        </select>
      </div>

      <p>Laden Sie beliebig viele eRezepte im PDF-Format hoch. Diese werden der Apotheke abgestellt.</p>
      <div class="dropbox">
        <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
               accept="application/pdf" class="input-file">
        <p v-if="isInitial">
          Ziehen Sie die Dateien per Drag&Drop auf dieses Feld<br> oder klicken Sie um Ihre Dateien zu durchsuchen
        </p>
        <p v-if="isSaving">
          Uploading {{ fileCount }} files...
        </p>
      </div>
    </form>

    <div class="mb-3" v-if="this.uploadError" @click="reset">
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
          {{ this.uploadError.data.message }}
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3" v-if="this.isSuccess">
    <h4 class="text-success">Erfolgreich verarbeitet:
      <span>{{ successfulFiles }}</span>
    </h4>
    <h4 v-bind:class="{ 'text-danger' : unsuccessfulFiles !== 0 }">
      Fehlgeschlagen: <span>{{ unsuccessfulFiles }}</span>
    </h4>
    <table class="table">
      <thead>
        <tr>
          <th>Datei</th>
          <th>Meldung</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rezept in results" :key="rezept.file" v-bind:class="{ 'bg-success text-white': rezept.message === 'success', 'bg-danger text-white': rezept.message !== 'success' }">
          <td>{{ rezept.file }}</td>
          <td>
            {{ rezept.message.replace("success", "Erfolgreich verarbeitet") }}
            <br>
            <span v-if="rezept.message === 'success'">
              Patient: {{ rezept.immunkarte.patient.surname }}, {{ rezept.immunkarte.patient.forename }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="isSuccess || isFailed" class="mb-3">
    <button type="button" class="btn btn-primary text-white" v-on:click="reset">Weitere Dateien hochladen</button>
  </div>
</template>

<script>
import RezeptService from '../services/rezept.service';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'app',
  data() {
    return {
      results: [],
      successfulFiles: 0,
      unsuccessfulFiles: 0,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'rezepts',
      fileCount: 0,
      selectedMandant: null
    }
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    user() {
      return this.$store.state.auth.user
    }
  },

  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.results = [];
      this.uploadError = null;
    },

    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING

      RezeptService.create(formData)
          .then(x => {
            this.results = x.data.results;
            this.successfulFiles = x.data.successful;
            this.unsuccessfulFiles = x.data.unsuccessful;
            this.currentStatus = STATUS_SUCCESS;
          })
          .catch(err => {
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
          });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length)
        return;

      const fileCountLimit = parseInt(process.env.VUE_APP_IMMUNKARTE_LIMIT)

      if (fileList.length > fileCountLimit) {
        this.uploadError = { data: { message: "Bitte laden Sie weniger als " + fileCountLimit +" Dateien hoch." } }
        this.currentStatus = STATUS_FAILED
        return;
      }

      if (!this.selectedMandant) {
        this.uploadError = { data: { message: "Bitte wählen Sie einen Mandanten aus" } }
        this.currentStatus = STATUS_FAILED
        return;
      }

      // append the files to FormData
      Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

      formData.append('mandantId', this.selectedMandant._id)

      // save it
      this.save(formData);
    }
  },
  mounted() {
    this.reset()
  },
}

</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>